import React from "react";
import { Link } from "gatsby";
import IconLogo from "../assets/icons/experro-logo.svg";


const LoginPage = () => {
    return (
        <div className="template-login">
            <div className="wrapper">
                <div className="header-wrapper"> 
                    <header>
                    <div className="site-header">
                        <div className="container">
                            <div className="custom-row d-flex row-2 flex-wrap align-v-center justify-space">
                                <div className="grid">
                                    <div className="logo-block">
                                        <Link to="/">
                                            <IconLogo />
                                        </Link>
                                    </div>
                                </div>
                                <div className="grid text-right">
                                    <p className="medium-text mb-0">Don't have an account? <Link to="/contact/">Contact Us</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </header>
                </div>
                <main>
                    <div className="container d-flex align-v-center justify-center">
                        <div className="login-form">
                            <h4>Welcome</h4>
                            <p className="mb-32 small-text">Sign in to your experro account.</p>
                            <form className="form">
                            <div className="form-field">
                                <label class="form-label small-text">Email</label>
                                <input class="form-input" required type="email"></input>
                            </div>
                            <div className="form-field">
                                <label class="form-label small-text">Password</label>
                                <input class="form-input"  type="password"></input>
                            </div>
                            <div className="bottom-links d-flex align-v-center justify-space">
                            <div className="checkmark d-flex align-v-center">
                                <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"></input>
                                <label className="form-label small-text mb-0" for="styled-checkbox-1">Remember me</label>
                            </div>
                            <div className="forgotpassword-block small-text"><Link to="#">Forgot Password?</Link></div>
                            </div>
                            <div className="form-actions">
                            <Link className="button button-small" to="#">Sign in</Link>
                            </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
  }
  
  export default LoginPage